function dateDiff(dateOld: Date, dateNew: Date): number {
  return dateNew.valueOf() - dateOld.valueOf()
}

export default defineNuxtRouteMiddleware((to, from) => {
  const { setters } = useUser()
  const token = useCookie('token') // get token from cookies
  const loginDate = useCookie('logindate')
  const date = new Date(loginDate.value || 0)
  const dateIsValid = (date && dateDiff(date, new Date()) < parseInt(process.env.SESSION_VALIDITY_S || '0') * 1000)
  if (token.value && dateIsValid) {
    // check if value exists
    setters.authenticated = true; // update the state to authenticated
  }
  // if token exists and url is /login redirect to homepage
  if (token.value && to?.name === 'login') {
    return navigateTo('/');
  }
  // if token doesn't exist redirect to log in
  if (!token.value && to?.name !== 'login') {
    abortNavigation();
    return navigateTo('/');
  }
})
